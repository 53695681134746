import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import Experts from "../components/Experts"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
import ExpertIcon from "../assets/icons/accounting.svg";
import ExpertImage from "../assets/images/sworn-accountant.webp";
const SwornAccountant = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Sworn Accountant" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "Sworn Accountant"
                : "محاسب محلف"
            }
            subTitle={
              language == false
                ? "Precision in Numbers: Trusted Expertise for Financial Integrity"
                : "الدقة في الأرقام: خبرة موثوقة لتحقيق النزاهة المالية"
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

      
              <Experts
                inverted={false}
                Icon={ExpertIcon}
                image={ExpertImage}
                title={
                  language == false
                    ? "Sworn Accountant"
                    : "محاسب محلف"
                }
                description={
                  language == false
                    ? "we bring you the pinnacle of financial integrity and precision through our sworn accountant services. Sworn accountants, distinguished by their rigorous certification and oath to uphold the highest standards of accounting practice, are uniquely equipped to handle the most intricate financial tasks with unwavering accuracy and reliability."
                    : "نحن نقدم لك قمة النزاهة والدقة المالية من خلال خدمات المحاسبين المحلفين لدينا. المحاسبون المحلفون، الذين يتميزون بشهاداتهم الصارمة وأقسمهم على الالتزام بأعلى معايير الممارسة المحاسبية، مجهزون بشكل فريد للتعامل مع المهام المالية الأكثر تعقيدًا بدقة وموثوقية لا تتزعزع."
                }
                label={
                  language == false
                  ? "Call our Accountant"
                  : "اتصل بمحاسبنا"
                }
                link={"tel:+9613948739"}
                arabic={language}
              />
          <br/><br/><br/>
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default SwornAccountant
